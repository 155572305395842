var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"9"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":_vm.$t('globalActions.find')},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)])],1)],1),_c('b-table',{ref:"refListTable",staticClass:"position-relative",attrs:{"id":"refListTable","no-provider-paging":"","no-provider-filtering":"","filter-included-fields":_vm.columnToBeFilter,"fields":_vm.tableColumns,"items":_vm.itemLists,"per-page":_vm.perPage,"current-page":_vm.currentPage,"filter":_vm.searchQuery,"responsive":"","primary-key":"id","show-empty":"","busy":_vm.isBusy,"empty-text":_vm.$t('globalSingular.noMatching'),"sort-desc":_vm.isSortDirDesc},on:{"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2 d-flex flex-column justify-content-center"},[_c('div',{staticClass:"text mb-1"},[_c('b-spinner',{staticClass:"align-middle"})],1),_c('strong',[_vm._v(_vm._s(_vm.$t('globalActions.fetchingDataAPI')))])])]},proxy:true},{key:"cell(code)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{staticClass:"font-weight-bolder",attrs:{"to":{ name: 'apps-ap-posting-detail', params: { id: item.id }}}},[_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.code)+" ")])])]}},{key:"cell(form_type)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s((item.contact) ? item.contact.label : 'Transfer')+" ")]),_c('small',{staticClass:"font-italic"},[_vm._v(_vm._s(item.form_type)+" "),_c('span',{staticClass:"font-weight-bolder text-primary "},[_vm._v("- "+_vm._s(_vm.companyActive.plan_id > 2 ? (item.office ? item.office.label : item.account.account_name) : item.account.account_name))])])]}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.date)+" ")])]}},{key:"cell(inv_number)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:['font-weight-bolder', 'd-block', 'text-nowrap', (item.amount > 0) ? 'text-success' : 'text-danger' ]},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.amount))+" ")])]}},{key:"cell(audit)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.updated_process)+" By "+_vm._s(item.getUser.user_name)+" ")]),_c('small',{staticClass:"font-italic"},[_vm._v(_vm._s(new Date(item.updated_at).toDateString()))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }