<template>
  <b-card no-body>
    <div class="m-2">
      <b-row>
         <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col>
        <!-- <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
            <b-button
                variant="primary"
                :to="{ name: 'apps-transactions-journalTemplate-add'}"
            >
                <feather-icon icon="PlusIcon" /> {{ $t('apps.transactions.purchase.postingAp.actions.create') }}
            </b-button>
        </b-col> -->
      </b-row>
    </div>

<b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-included-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
    <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: form number -->
      <template #cell(code)="{item}">
        <b-link
          :to="{ name: 'apps-ap-posting-detail', params: { id: item.id }}"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            {{ item.code }}
          </span>
        </b-link>
      </template>

      <!-- Column: Type -->
       <template #cell(form_type)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap"> 
          {{ (item.contact) ? item.contact.label : 'Transfer' }}
        </span>
        <small class="font-italic">{{ item.form_type }} <span class="font-weight-bolder text-primary ">- {{ companyActive.plan_id > 2 ? (item.office ? item.office.label : item.account.account_name) : item.account.account_name }}</span>  </small>
      </template>

        <!-- Column: Date -->
       <template #cell(date)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.date }}
        </span>
      </template>

        <!-- Column: Inv Number -->
       <template #cell(inv_number)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.code }}
        </span>
      </template>

        <!-- Column: description-->
       <!-- <template #cell(description)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.description }}
        </span>
      </template> -->

      <!-- Column: Amount -->
      <template #cell(amount)="{ item }">
        <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', (item.amount > 0) ? 'text-success' : 'text-danger' ]">
          {{
            formatCurrency(item.amount)
          }}
        </span>
      </template>

      <!-- Column: Audit -->
      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        {{ item.updated_process }}  By {{ item.getUser.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

    </b-table>
  </b-card>
</template>
<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BSpinner
} from 'bootstrap-vue'

import useListTable from '@/comp-functions/useListTable'
import { onMounted } from '@vue/composition-api'
import { formatCurrency } from '@/utils/formatter'
import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BSpinner
  },
  setup () {
    const { $t } = useLocalization()

    const tableColumns = [
      { key: 'code', label: 'Form Number' },
      { key: 'date', label: 'Date', sortable: true },
      { key: 'office.brand', label: $t('globalSingular.branch') },
      { key: 'form_type', label: 'Contact' },
      { key: 'no_invoices', label: 'Inv Num' }, 
      { key: 'description', label: 'Description' },
      { key: 'amount', label: 'Req Amount' },
      { key: 'updatedBy.user_name', label: 'Requester' },
      { key: 'audit', label: $t('globalSingular.audit') }
    ]

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      changePerPage
    } = useListTable({ url: 'transaction/purchase/posting-ap' })

    onMounted(() => {
      changePerPage(30)
    })
    // Table Handlers
    const columnToBeFilter = [
      'code',
      'date',
      'form_type',
      'no_invoices',
      'amount',
      'audit',
      'contact'
    ]

    return {
      formatCurrency,
      tableColumns,
      columnToBeFilter,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      changePerPage
    }
  },
  computed: {
    companyActive () {
      return this.$store.state.user.currentCompany
    }
  }
}
</script>